import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  Stack,
  CircularProgress,
  TableRow,
  TextField,
  Checkbox,
  TableSortLabel,
  Box,
  useTheme,
} from '@mui/material';
import useWindowDimensions from '../../../common/react-hooks/use-window-dimensions';
import PalletButtonGroup from '../../../pallet-ui/button-group/pallet-button-group';
import { values } from 'lodash';
import PalletDateRangePicker from '../../../pallet-ui/date-range-picker/pallet-date-range-picker';
import DriverFilterButton from '../../../common/components/driver-filter-button';
import {
  useGetSettlementBills,
  SETTLEMENT_BILLS_ROW_PER_PAGE_OPTIONS,
} from '../hooks/use-get-settlement-bills';
import { SettlementBillRow } from './settlement-bill-row';
import { CHECKBOX_CELL_WIDTH } from './common';
import { useState } from 'react';
import { SortType } from '../../../common/components/sort-component';
import { isNil } from 'lodash';
import SettlementBill from './settlement-bill';
import { SettlementBillsTab } from '../enums';
import { useFinalizeDriverSettlementBillsMutation } from '../../../generated/graphql';
import { useConfirm } from 'material-ui-confirm';
import PalletButton from '../../../pallet-ui/button/pallet-button';
import pluralize from 'pluralize';

export const SettlementBillsTable = () => {
  const {
    loading,
    page,
    rowsPerPage,
    tab,
    startDate,
    endDate,
    driverOptions,
    searchText,
    settlementBills,
    totalCount,
    handlePageChange,
    handleRowsPerPageChange,
    handleTabChange,
    handleDriverOptionsChange,
    handleDateRangeChange,
    handleSearchTextChange,
    selectedSettlementBillIds,
    handleSelectSettlementBill,
    handleSelectAllSettlementBills,
    settlementDateSort,
    handleSettlementDateSort,
    refresh,
  } = useGetSettlementBills();

  const { height } = useWindowDimensions();
  const theme = useTheme();

  const [openedSettlementBillUuid, setOpenedSettlementBillUuid] = useState<
    string | undefined
  >();

  const confirm = useConfirm();

  const onCheckboxChange = (settlementBillId: string) => {
    handleSelectSettlementBill(settlementBillId);
  };

  const [currentSearchText, setCurrentSearchText] =
    useState<string>(searchText);

  const handleSearch = () => {
    handleSearchTextChange(currentSearchText);
  };

  const toggleOpenedSettlementBill = (uuid: string) => {
    setOpenedSettlementBillUuid((current) =>
      current === uuid ? undefined : uuid,
    );
  };

  const [finalizeSettlements, { loading: finalizeSettlementsLoading }] =
    useFinalizeDriverSettlementBillsMutation({
      onCompleted: () => {
        void refresh();
      },
    });

  const handleFinalizeSettlements = async () => {
    await confirm({
      title: `Are you sure you want to finalize ${selectedSettlementBillIds.size} driver settlement ${pluralize(
        'bill',
        selectedSettlementBillIds.size,
      )}?`,
      description: '',
      cancellationText: `Cancel`,
      confirmationText: `Confirm`,
      allowClose: false,
    });

    await finalizeSettlements({
      variables: {
        uuids: [...selectedSettlementBillIds],
      },
    });
  };

  return (
    <Stack direction="column" height="100%" spacing={1}>
      <Stack
        direction="row"
        justifyContent="space-between"
        padding={1}
        sx={{ height: '40px' }}
        alignItems="center"
        marginTop={1}
      >
        <Stack direction="row" spacing={1} alignItems="center">
          <PalletButtonGroup
            size="small"
            options={values(SettlementBillsTab).map((tab) => ({
              label: tab,
              value: tab,
            }))}
            value={tab}
            onChange={handleTabChange}
          />
          <DriverFilterButton
            selectedOptionsMultiselect={driverOptions}
            handleChangeMultiselect={handleDriverOptionsChange}
            prefixText="Driver"
          />
          <PalletDateRangePicker
            isClearable
            value={[startDate, endDate]}
            label="Settlement date"
            isRequired={false}
            sx={{ width: '225px' }}
            onAccept={handleDateRangeChange}
          />
          <TextField
            size="small"
            placeholder="Search"
            value={currentSearchText}
            sx={{ width: '200px' }}
            onChange={(e) => {
              setCurrentSearchText(e.target.value);
            }}
            onBlur={handleSearch}
            onKeyDown={(e) => {
              if (e.key === 'Enter') {
                handleSearch();
              }
            }}
          />
        </Stack>
        <Stack direction="row" spacing={1} alignItems="center">
          <PalletButton
            disabled
            variant="contained"
            // disabled={selectedSettlementBillIds.size === 0}
            onClick={() => {
              // Implement download
            }}
          >
            Download
            {selectedSettlementBillIds.size > 0 &&
              ` (${selectedSettlementBillIds.size})`}
          </PalletButton>
          {tab === SettlementBillsTab.NOT_FINALIZED && (
            <PalletButton
              variant="contained"
              disabled={selectedSettlementBillIds.size === 0}
              loading={finalizeSettlementsLoading}
              onClick={handleFinalizeSettlements}
            >
              Finalize
              {selectedSettlementBillIds.size > 0 &&
                ` (${selectedSettlementBillIds.size})`}
            </PalletButton>
          )}
          <TablePagination
            rowsPerPageOptions={SETTLEMENT_BILLS_ROW_PER_PAGE_OPTIONS}
            labelRowsPerPage="Show"
            component="div"
            count={totalCount}
            page={page}
            rowsPerPage={rowsPerPage}
            onPageChange={async (_, newPage) => {
              await handlePageChange({ newPage, forward: newPage > page });
            }}
            onRowsPerPageChange={async (event) => {
              const pageSize = Number(event.target.value);
              await handleRowsPerPageChange(pageSize);
            }}
          />
        </Stack>
      </Stack>
      <Box flex="1" minHeight="0" sx={{ overflow: 'auto' }}>
        <Stack direction="row" sx={{ height: '100%', width: '100%' }}>
          <Box flex={1}>
            <TableContainer
              sx={{
                border: 1,
                borderColor: theme.palette.borderColor.main,
              }}
            >
              <Table
                stickyHeader
                aria-label="settlement-bills-table"
                size="small"
                sx={{
                  '& .MuiTableCell-sizeSmall': {
                    padding: '5px 5px',
                  },
                }}
              >
                <TableHead>
                  <TableRow>
                    <TableCell sx={{ width: CHECKBOX_CELL_WIDTH }}>
                      <Checkbox
                        checked={
                          selectedSettlementBillIds.size ===
                            settlementBills.length && settlementBills.length > 0
                        }
                        indeterminate={
                          selectedSettlementBillIds.size > 0 &&
                          selectedSettlementBillIds.size <
                            settlementBills.length
                        }
                        onChange={(e) => {
                          handleSelectAllSettlementBills(e.target.checked);
                        }}
                      />
                    </TableCell>
                    <TableCell>
                      <TableSortLabel
                        active
                        direction={
                          settlementDateSort === SortType.Descending
                            ? 'desc'
                            : 'asc'
                        }
                        onClick={handleSettlementDateSort}
                      >
                        Settlement date
                      </TableSortLabel>
                    </TableCell>
                    <TableCell>Pay period</TableCell>
                    <TableCell>Name</TableCell>
                    <TableCell>Driver</TableCell>
                  </TableRow>
                </TableHead>
                {loading ? (
                  <CircularProgress size={20} />
                ) : (
                  <TableBody>
                    {settlementBills.map((settlementBill) => (
                      <SettlementBillRow
                        key={settlementBill.uuid}
                        settlementBill={settlementBill}
                        isChecked={selectedSettlementBillIds.has(
                          settlementBill.uuid,
                        )}
                        toggleOpenedSettlementBill={toggleOpenedSettlementBill}
                        isOpened={
                          settlementBill.uuid === openedSettlementBillUuid
                        }
                        onCheckboxChange={onCheckboxChange}
                      />
                    ))}
                  </TableBody>
                )}
              </Table>
            </TableContainer>
          </Box>

          {!isNil(openedSettlementBillUuid) && (
            <Box
              flex={1.5}
              sx={{
                border: 1,
                borderColor: theme.palette.borderColor.main,
              }}
            >
              <SettlementBill
                settlementUuid={openedSettlementBillUuid}
                onClose={() => {
                  setOpenedSettlementBillUuid(undefined);
                }}
              />
            </Box>
          )}
        </Stack>
      </Box>
    </Stack>
  );
};

export default SettlementBillsTable;
