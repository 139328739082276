import {
  Button,
  Card,
  CardContent,
  Checkbox,
  CircularProgress,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormHelperText,
  FormLabel,
  MenuItem,
  Select,
  Stack,
  TextField,
  Typography,
  useTheme,
} from '@mui/material';
import { isNil } from 'lodash';
import {
  Controller,
  type SubmitHandler,
  useFormContext,
  useWatch,
} from 'react-hook-form';
import useMe from '../../../../common/react-hooks/use-me';
import {
  OrganizationType,
  useBusinessDivisionsQuery,
  useCreateOrganizationMutation,
  useOrganizationForFrontendHookQuery,
} from '../../../../generated/graphql';
import DefaultAddressAutocomplete from '../../components/default-address-autocomplete';
import DefaultContactPersonAutocomplete from '../../components/default-contact-person-autocomplete';
import OrganizationNotes from '../../components/organization-notes';
import { useOrganizationFormContext } from '../../contexts/organization-form-context';
import { OrganizationPageMode } from '../../enums';
import { type OrganizationFormValues } from '../../form/types';
import { useStyles } from '../../styles';
import ServiceLevelConfigCard from './service-level-config-card';
import EdiConfigCard from './edi-config-card';
import BillingConfigCard from './billing-config-card';
import VehicleTypesConfigCard from './vehicle-types-config-card';
import ReferenceNumberLabelsConfigCard from './reference-number-labels-config-card';
import PalletButton from '../../../../pallet-ui/button/pallet-button';
import useOrganizationForm from '../../form/use-organization-form';
import { type FunctionComponent, useEffect, useState } from 'react';
import { type OrganizationPageProps } from '../../enums';

const GeneralTabForm: FunctionComponent<
  OrganizationPageProps & {
    readonly formLoading: boolean;
    readonly setSelectedOrganizationTypes: (types: OrganizationType[]) => void;
    readonly isAgent: boolean;
    readonly isCustomer: boolean;
    readonly onSubmit: () => void;
    readonly submitLoading: boolean;
    readonly initialTypes: OrganizationType[];
  }
> = ({
  formLoading,
  setSelectedOrganizationTypes,
  isAgent,
  isCustomer,
  onSubmit,
  submitLoading,
  initialTypes,
  ...props
}) => {
  const {
    control,
    formState: { isDirty },
  } = useFormContext<OrganizationFormValues>();
  const { mode, uuid } = props;

  const styles = useStyles();
  //   const isAgent = useWatch({ control, name: 'isAgent' });
  //   const isCustomer = useWatch({ control, name: 'isCustomer' });
  const { data: organizationData } = useOrganizationForFrontendHookQuery({
    skip: isNil(uuid) || mode === OrganizationPageMode.CREATE,
    variables: {
      input: { uuid: uuid ?? '' },
    },
  });

  const [uncheckExistingTypeError, setUncheckExistingTypeError] =
    useState(false);

  const { userUuid, loading: meLoading } = useMe();

  // these are fields that are only relevant to customers
  const showBusinessDivision = isCustomer;
  const showIdentifier = isCustomer;
  const showDefaultAddress = isCustomer;
  const showDefaultContactPerson = isCustomer || isAgent;
  const showServiceLevels = isCustomer;
  const showVehicleTypes = isCustomer;
  const showReferenceNumberLabels = isCustomer;
  const showBillingConfig = isCustomer;
  const showEdiConfig = isCustomer || isAgent;

  const { data: businessDivisionsData } = useBusinessDivisionsQuery({
    skip: !showBusinessDivision,
  });
  const businessDivisions = businessDivisionsData?.businessDivisions;

  if (formLoading) {
    return <CircularProgress />;
  }

  return (
    <Stack width="100%" gap={2}>
      <Stack direction="row" justifyContent="flex-end">
        {mode !== OrganizationPageMode.VIEW && (
          <PalletButton
            variant="contained"
            disabled={!isDirty}
            loading={submitLoading}
            onClick={onSubmit}
          >
            {mode === OrganizationPageMode.CREATE ? 'Create' : 'Save'}
          </PalletButton>
        )}
      </Stack>
      <Stack direction="row" gap={2}>
        <Card
          sx={{
            flex: 3,
            minHeight: '200px',
            ...styles.card,
          }}
        >
          <CardContent sx={styles.cardContent}>
            <Stack gap={2}>
              <Typography variant="h6">General</Typography>
              <Stack direction="row" gap={2}>
                <Controller
                  name="name"
                  control={control}
                  render={({ field, fieldState: { error } }) => (
                    <FormControl
                      required
                      sx={{ flex: 1 }}
                      error={!isNil(error)}
                    >
                      <FormLabel>Name</FormLabel>
                      <TextField
                        {...field}
                        sx={{ width: '80%' }}
                        placeholder="Name"
                        variant="outlined"
                        helperText={error?.message}
                      />
                    </FormControl>
                  )}
                />
                <Controller
                  name="types"
                  control={control}
                  defaultValue={[]}
                  render={({
                    field: { value = [], onChange },
                    fieldState: { error },
                  }) => (
                    <FormControl
                      required
                      sx={{ flex: 1 }}
                      error={!isNil(error)}
                    >
                      <FormLabel>Account type</FormLabel>
                      <FormGroup row sx={{ gap: 5 }}>
                        {Object.values(OrganizationType).map((enumValue) => (
                          <FormControlLabel
                            key={enumValue}
                            control={
                              <Checkbox
                                checked={value.includes(enumValue)}
                                onChange={(e) => {
                                  const newValue = e.target.checked
                                    ? [...value, enumValue]
                                    : value.filter((v) => v !== enumValue);

                                  // if the initial types has values not in new values
                                  if (
                                    initialTypes.some(
                                      (type) => !newValue.includes(type),
                                    )
                                  ) {
                                    setUncheckExistingTypeError(true);
                                  } else {
                                    setUncheckExistingTypeError(false);
                                  }

                                  onChange(newValue);
                                  setSelectedOrganizationTypes(newValue);
                                }}
                              />
                            }
                            label={enumValue}
                          />
                        ))}
                      </FormGroup>
                      {!isNil(error) && (
                        <FormHelperText>{error.message}</FormHelperText>
                      )}
                      <FormHelperText color="error">
                        {uncheckExistingTypeError &&
                          'Cannot uncheck an existing account type'}
                      </FormHelperText>
                    </FormControl>
                  )}
                />
              </Stack>
              <Stack direction="row" gap={2}>
                <Controller
                  name="email"
                  control={control}
                  render={({ field, fieldState: { error } }) => (
                    <FormControl
                      required
                      sx={{ flex: 1 }}
                      error={!isNil(error)}
                    >
                      <FormLabel>Email</FormLabel>
                      <TextField
                        {...field}
                        placeholder="Email"
                        variant="outlined"
                        helperText={error?.message}
                      />
                    </FormControl>
                  )}
                />
                <Controller
                  name="referenceNumber"
                  control={control}
                  render={({ field, fieldState: { error } }) => (
                    <FormControl
                      required
                      sx={{ flex: 1 }}
                      error={!isNil(error)}
                    >
                      <FormLabel>Account ID</FormLabel>
                      <TextField
                        {...field}
                        placeholder="Account ID"
                        variant="outlined"
                        helperText={error?.message}
                      />
                    </FormControl>
                  )}
                />
              </Stack>
              <Stack direction="row" gap={2}>
                {showBusinessDivision && (
                  <Controller
                    name="businessDivisionUuid"
                    control={control}
                    render={({ field, fieldState: { error } }) => (
                      <FormControl sx={{ flex: 1 }} error={!isNil(error)}>
                        <FormLabel>Business Division</FormLabel>
                        <Select
                          {...field}
                          id="business-division-select"
                          disabled={mode === OrganizationPageMode.VIEW}
                          value={field.value ?? 'None'}
                        >
                          <MenuItem value="None">None</MenuItem>
                          {businessDivisions?.businessDivisions.map(
                            (businessDivision) => (
                              <MenuItem
                                key={businessDivision.uuid}
                                value={businessDivision.uuid}
                              >
                                {businessDivision.name}
                              </MenuItem>
                            ),
                          )}
                        </Select>
                        {!isNil(error) && (
                          <FormHelperText>{error.message}</FormHelperText>
                        )}
                      </FormControl>
                    )}
                  />
                )}
                {showIdentifier && (
                  <Controller
                    name="invoiceOrOrderIdentifier"
                    control={control}
                    render={({ field, fieldState: { error } }) => (
                      <FormControl
                        required
                        sx={{ flex: 1 }}
                        error={!isNil(error)}
                      >
                        <FormLabel>Invoice/Order Identifier</FormLabel>
                        <TextField
                          {...field}
                          sx={{ flex: 1 }}
                          placeholder="Invoice/Order Identifier"
                          variant="outlined"
                          helperText={error?.message}
                        />
                      </FormControl>
                    )}
                  />
                )}
              </Stack>
              <Stack direction="row" gap={2}>
                {showDefaultContactPerson && (
                  <DefaultContactPersonAutocomplete
                    control={control}
                    organizationContactPersons={
                      organizationData?.organization?.contactPersons ?? []
                    }
                  />
                )}
                {showDefaultAddress ? (
                  <DefaultAddressAutocomplete
                    control={control}
                    organizationAddresses={
                      organizationData?.organization?.addresses ?? []
                    }
                  />
                ) : (
                  // add a flex so the contact person autocomplete doesn't fill
                  <Stack flex={1} />
                )}
              </Stack>
            </Stack>
          </CardContent>
        </Card>
        {mode === OrganizationPageMode.EDIT && !isNil(uuid) && !meLoading && (
          <Card sx={{ flex: 2, ...styles.card }}>
            <CardContent sx={styles.cardContent}>
              <Stack gap={2}>
                <Typography variant="h6">Notes</Typography>
                <OrganizationNotes
                  organizationUuid={uuid}
                  userUuid={userUuid}
                />
              </Stack>
            </CardContent>
          </Card>
        )}
      </Stack>
      {showServiceLevels && <ServiceLevelConfigCard mode={mode} />}
      {showEdiConfig && <EdiConfigCard mode={mode} />}
      {showBillingConfig && <BillingConfigCard mode={mode} />}
      {showVehicleTypes && <VehicleTypesConfigCard mode={mode} />}
      {showReferenceNumberLabels && (
        <ReferenceNumberLabelsConfigCard mode={mode} />
      )}
    </Stack>
  );
};

export default GeneralTabForm;
