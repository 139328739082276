import {
  QuoteSource,
  type FindCustomerPortalQuotesInput,
  useCustomerPortalQuotesQuery,
  type CustomerPortalQuotesQueryVariables,
  type CustomerPortalQuotesQuery,
} from '../../../../../generated/graphql';
import { useCallback } from 'react';
import { type QueryHookOptions } from '@apollo/client';
import useMeAsThirdPartyUser from '../../../../../common/react-hooks/use-me-as-third-party-user';

const getCustomerPortalQuoteFilters = () => ({
  filters: {
    sourceFilter: {
      eq: QuoteSource.CustomerPortal,
    },
  },
});

type PaginationVariables = Pick<
  FindCustomerPortalQuotesInput,
  'first' | 'last' | 'after' | 'before'
>;

/**
 * Wraps the customerPortalQuotes query with a fixed set of filters and
 * fields to fetch pending customer portal quotes.
 */
export const useCustomerPortalQuotes = ({
  first,
  last,
  after,
  before,
  ...queryHookOptions
}: PaginationVariables &
  QueryHookOptions<
    CustomerPortalQuotesQuery,
    CustomerPortalQuotesQueryVariables
  >) => {
  const { loading: meAsThirdPartyUserLoading } = useMeAsThirdPartyUser();

  const { data, loading, error, refetch } = useCustomerPortalQuotesQuery({
    variables: {
      findCustomerPortalQuotesInput: {
        ...getCustomerPortalQuoteFilters(),
        first,
        last,
        after,
        before,
      },
    },
    ...queryHookOptions,
    skip: meAsThirdPartyUserLoading || queryHookOptions.skip,
  });

  const refetchWithInitialVariables = useCallback(
    async (variables: Omit<FindCustomerPortalQuotesInput, 'filters'>) =>
      refetch({
        findCustomerPortalQuotesInput: {
          ...variables,
          ...getCustomerPortalQuoteFilters(),
        },
      }),
    [refetch],
  );

  return {
    ...data?.customerPortalQuotes,
    loading,
    error,
    refetch: refetchWithInitialVariables,
    totalCount: data?.customerPortalQuotes?.totalCount ?? undefined,
  };
};
