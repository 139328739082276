import { Checkbox, Stack, Typography } from '@mui/material';
import {
  AgentSelect,
  type AgentOption,
} from '../../../../../common/components/agent-select';
import { type MutationErrorOutput } from '../../../../../generated/graphql';

export const AgentCheckboxAndSelect = ({
  agentOption,
  useAgent,
  setAgentOption,
  setUseAgent,
  onError,
}: {
  readonly agentOption: AgentOption | null;
  readonly useAgent: boolean;
  readonly setAgentOption: (agentOption: AgentOption | null) => void;
  readonly setUseAgent: (useAgent: boolean) => void;
  readonly onError: (error: Error | MutationErrorOutput | string) => void;
}) => {
  const handleCheckAgent = (checked: boolean) => {
    setUseAgent(checked);
    if (!checked) {
      setAgentOption(null);
    }
  };

  return (
    <Stack gap={1}>
      <Stack direction="row" alignItems="center" gap={1}>
        <Checkbox
          checked={useAgent}
          sx={{ p: 0 }}
          onChange={(e) => {
            handleCheckAgent(e.target.checked);
          }}
        />
        <Typography>This terminal belongs to an agent</Typography>
      </Stack>
      {useAgent && (
        <AgentSelect
          selectedOption={agentOption}
          onChange={setAgentOption}
          onError={onError}
        />
      )}
    </Stack>
  );
};
