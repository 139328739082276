import { safeAdd } from 'shared/math';
import type { LineHaulManifestsQuery } from '../../../../generated/graphql';

export const isNonEmptyArrayEntry = <T>(
  entry: [string, T[]],
): entry is [string, NonEmptyArray<T>] => {
  return isNonEmptyArray(entry[1]);
};

export type LineHaulManifest =
  LineHaulManifestsQuery['lineHaulManifests']['lineHaulManifests'][number];

export type NonEmptyArray<T> = [T, ...T[]];

export const isNonEmptyArray = <T>(arr: T[]): arr is NonEmptyArray<T> => {
  return arr.length > 0;
};
export const getManifestPieces = (manifest: LineHaulManifest): number => {
  let sum = 0;
  for (const order of manifest.orders) {
    sum = safeAdd(sum, order.pieceCountFromPackages ?? 0);
  }
  return sum;
};

export const getManifestWeight = (manifest: LineHaulManifest): number => {
  let sum = 0;
  for (const order of manifest.orders) {
    for (const p of order.packages) {
      sum = safeAdd(sum, p.weight ?? 0);
    }
  }
  return sum;
};

export const getManifestDimWeight = (manifest: LineHaulManifest): number => {
  let sum = 0;
  for (const order of manifest.orders) {
    sum = safeAdd(sum, order.dimWeight);
  }
  return sum;
};
