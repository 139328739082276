import { Stack, Button, Typography, useTheme } from '@mui/material';
import PalletModal from '../../../pallet-ui/modal/pallet-modal';
const UnsavedChangesModal = ({
  open,
  onCancel,
  onDiscard,
  onSave,
}: {
  readonly open: boolean;
  readonly onCancel: () => void;
  readonly onDiscard: () => void;
  readonly onSave: () => void;
}) => {
  const theme = useTheme();
  return (
    <PalletModal
      open={open}
      title="There are unsaved changes"
      pinnedElements={{
        bottomRight: (
          <Stack direction="row" gap={1}>
            <Button
              variant="contained"
              sx={{ backgroundColor: theme.palette.errorRed[60] }}
              onClick={onDiscard}
            >
              Discard changes
            </Button>
            <Button variant="contained" onClick={onSave}>
              Save changes
            </Button>
          </Stack>
        ),
        bottomLeft: <Button onClick={onCancel}>Cancel</Button>,
      }}
      onClose={onCancel}
    >
      <Stack sx={{ minWidth: '400px', gap: 1 }}>
        <Typography>Do you want to save your changes?</Typography>
      </Stack>
    </PalletModal>
  );
};

export default UnsavedChangesModal;
