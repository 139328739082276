import { isNil } from 'lodash';
import { useCallback } from 'react';
import { useFormContext } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { shallow } from 'zustand/shallow';
import { EnvironmentVariables } from '../../../../../environment-variables';
import useGlobalStore from '../../../../../layouts/dashboard/global-store';
import { type CustomerPortalOrderFormValues } from '../../customer-portal-order-form/forms/types';
import { getCreateQuoteV1Request } from '../forms/utils';

/**
 * Hook for saving quotes in the customer portal.
 *
 * This expects to be called with third-party user auth,
 * so it should only be used in the customer portal.
 *
 * This also expects to be called within the CustomerPortalOrderFormValues RHF context.
 */
const useSaveQuoteCustomerPortal = ({
  companyUuid,
  contactUuid,
}: {
  companyUuid: string;
  contactUuid: string;
}) => {
  const { handleSubmit, clearErrors } =
    useFormContext<CustomerPortalOrderFormValues>();
  const navigate = useNavigate();
  const [
    setSuccessMessage,
    setShowSuccessMessage,
    setErrorMessage,
    setShowErrorMessage,
  ] = useGlobalStore(
    (state) => [
      state.setSuccessMessage,
      state.setShowSuccessMessage,
      state.setErrorMessage,
      state.setShowErrorMessage,
    ],
    shallow,
  );

  const errorCallback = useCallback(
    (e: unknown) => {
      // We generally try to avoid console logs in production, but this is very
      // helpful for debugging issues where customers can't save quotes in prod.
      // eslint-disable-next-line no-console
      console.error(`Error saving quote: ${JSON.stringify(e)}`);
      setErrorMessage(e instanceof Error ? e.message : 'Error saving quote');
      setShowErrorMessage(true);
    },
    [setErrorMessage, setShowErrorMessage],
  );

  /**
   * Submits the quote to the backend once the form validation succeeds
   *
   * @param orderValues - The current form values from RHF's handleSubmit
   * Use handleSubmit's success callback to get the order values so that
   * Zod transformations (e.g. trimming strings) are applied.
   */
  const successCallback = useCallback(
    async (orderValues: CustomerPortalOrderFormValues) => {
      const createQuoteV1Request = getCreateQuoteV1Request({
        orderValues,
        clientId: companyUuid,
        customerId: contactUuid,
      });

      try {
        const res = await fetch(
          `${EnvironmentVariables.VITE_BACKEND_URL}/v1/quotes`,
          {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            credentials: 'include',
            body: JSON.stringify(createQuoteV1Request),
          },
        );

        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
        const data = await res.json();
        if (res.status !== 201 || !isNil(data.error)) {
          throw new Error(
            (data.error as string | undefined) ?? 'Error saving quote',
          );
        }

        setSuccessMessage('Quote saved');
        setShowSuccessMessage(true);
        navigate('/customer-portal/quotes');
      } catch (error) {
        errorCallback(error);
      }
    },
    [
      companyUuid,
      contactUuid,
      navigate,
      errorCallback,
      setSuccessMessage,
      setShowSuccessMessage,
    ],
  );

  const saveQuote = useCallback(async () => {
    clearErrors();
    await handleSubmit(successCallback, errorCallback)();
  }, [clearErrors, handleSubmit, successCallback, errorCallback]);

  return {
    /**
     * Saves the quote from the current form values and handles navigation.
     */
    saveQuote,
  };
};

export { useSaveQuoteCustomerPortal };
