import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import {
  Button,
  Checkbox,
  CircularProgress,
  Collapse,
  IconButton,
  Stack,
  TableRow,
  Tooltip,
} from '@mui/material';
import { sentenceCase } from 'change-case';
import currency from 'currency.js';
import dayjs from 'dayjs';
import { isNil } from 'lodash';
import { useEffect, useState, type Dispatch, type SetStateAction } from 'react';
import { useSettlementBillLineItemWithCostsLazyQuery } from '../../../generated/graphql';
import { SettlementBillLineItemsTableMode } from '../enums';
import { type SettlementBillLineItem } from '../types';
import { CHECKBOX_CELL_WIDTH, CompactRow, SettlementTableCell } from './common';
import SettlementBillLineItemCostsSummary from './settlement-bill-line-item-costs-summary';

type SettlementBillLineItemRowProps = {
  readonly settlementBillLineItem: SettlementBillLineItem;
  readonly isChecked: boolean;
  readonly onCheckboxChange: (settlementBillLineItemId: string) => void;
  readonly setOpenedOrderUuid: Dispatch<SetStateAction<string | null>>;
  readonly mode: SettlementBillLineItemsTableMode;
};

export const SettlementBillLineItemsRow = ({
  settlementBillLineItem,
  isChecked,
  onCheckboxChange,
  setOpenedOrderUuid,
  mode,
}: SettlementBillLineItemRowProps) => {
  const [isHovered, setIsHovered] = useState(false);
  const [isExpanded, setIsExpanded] = useState(false);
  const [
    getLineItemWithCosts,
    { data: lineItemWithCostsData, loading: lineItemWithCostsLoading },
  ] = useSettlementBillLineItemWithCostsLazyQuery();

  const { shipment, driver, driverSettlementBill } = settlementBillLineItem;
  const order = shipment?.order;
  const stop = shipment?.legs[0]?.endStop;

  useEffect(() => {
    if (isExpanded) {
      void getLineItemWithCosts({
        variables: {
          id: settlementBillLineItem.id,
        },
      });
    }
  }, [isExpanded, settlementBillLineItem.id, getLineItemWithCosts]);

  const formattedCompletedAt = isNil(stop?.completedAt)
    ? 'Incomplete'
    : // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
      dayjs(stop.completedAt).format('MM/DD/YY');

  const refNumbers = order?.refNumbers.join(', ');

  return (
    <>
      <CompactRow
        sx={{
          backgroundColor:
            isExpanded || isHovered ? 'rgba(0, 0, 0, 0.04)' : 'transparent',
        }}
        onMouseEnter={() => {
          setIsHovered(true);
        }}
        onMouseLeave={() => {
          setIsHovered(false);
        }}
        onClick={() => {
          setIsExpanded(!isExpanded);
        }}
      >
        <SettlementTableCell sx={{ width: CHECKBOX_CELL_WIDTH }}>
          {mode === SettlementBillLineItemsTableMode.LINE_ITEMS_TABLE ? (
            <Checkbox
              checked={isChecked}
              onClick={(e) => {
                e.stopPropagation();
                onCheckboxChange(settlementBillLineItem.id);
              }}
            />
          ) : (
            <IconButton
              size="small"
              onClick={(e) => {
                e.stopPropagation();
                setIsExpanded(!isExpanded);
              }}
            >
              {isExpanded ? (
                <KeyboardArrowDownIcon />
              ) : (
                <KeyboardArrowRightIcon />
              )}
            </IconButton>
          )}
        </SettlementTableCell>
        <SettlementTableCell>{formattedCompletedAt}</SettlementTableCell>
        {mode === SettlementBillLineItemsTableMode.LINE_ITEMS_TABLE && (
          <SettlementTableCell>{driver?.user.name}</SettlementTableCell>
        )}
        {mode === SettlementBillLineItemsTableMode.LINE_ITEMS_TABLE && (
          <SettlementTableCell>
            {sentenceCase(driver?.driverType ?? '-')}
          </SettlementTableCell>
        )}
        <SettlementTableCell>
          <Tooltip
            title={order?.billingPartyContact?.displayName}
            placement="top"
          >
            <span>{order?.billingPartyContact?.displayName}</span>
          </Tooltip>
        </SettlementTableCell>
        <SettlementTableCell sx={{ maxWidth: '25px' }}>
          {sentenceCase(stop?.stopType ?? '-')}
        </SettlementTableCell>
        <SettlementTableCell>
          <Tooltip
            title={order?.standardOrderFields?.shipperBillOfLadingNumber}
            placement="top"
          >
            <span>{order?.standardOrderFields?.shipperBillOfLadingNumber}</span>
          </Tooltip>
        </SettlementTableCell>
        <SettlementTableCell>{order?.name}</SettlementTableCell>
        <SettlementTableCell>
          {' '}
          <Tooltip title={refNumbers} placement="top">
            <span>{refNumbers}</span>
          </Tooltip>
        </SettlementTableCell>
        <SettlementTableCell>
          {currency(settlementBillLineItem.totalAmountUsdDollars ?? 0).format()}
        </SettlementTableCell>
        <SettlementTableCell>
          {!isNil(order?.uuid) && (
            <Button
              variant="contained"
              size="small"
              sx={{
                visibility: isHovered ? 'visible' : 'hidden',
              }}
              onClick={(e) => {
                setOpenedOrderUuid(order.uuid);
              }}
            >
              Open
            </Button>
          )}
        </SettlementTableCell>
      </CompactRow>
      {mode === SettlementBillLineItemsTableMode.SETTLEMENT_BILL && (
        <TableRow>
          <SettlementTableCell
            style={{ paddingBottom: 0, paddingTop: 0 }}
            colSpan={8}
          >
            <Collapse unmountOnExit in={isExpanded} timeout="auto">
              <Stack spacing={1} sx={{ pl: '40px' }}>
                {lineItemWithCostsLoading && <CircularProgress size={20} />}
                {!isNil(lineItemWithCostsData?.settlementBillLineItem) && (
                  <SettlementBillLineItemCostsSummary
                    lineItem={lineItemWithCostsData.settlementBillLineItem}
                  />
                )}
              </Stack>
            </Collapse>
          </SettlementTableCell>
        </TableRow>
      )}
    </>
  );
};
