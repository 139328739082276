import { useMemo, useState } from 'react';
import { useGetSettlementBillLineItemsQuery } from '../../../generated/graphql';
import { type Dayjs } from 'dayjs';
import { type Option } from '../../../common/filters/types';
import { isEmpty, isNil } from 'lodash';

export const SETTLEMENT_BILL_LINE_ITEMS_ROW_PER_PAGE_OPTIONS = [25, 50];

export const useGetSettlementBillLineItems = () => {
  const [page, setPage] = useState<number>(0);
  const [rowsPerPage, setRowsPerPage] = useState<number>(
    SETTLEMENT_BILL_LINE_ITEMS_ROW_PER_PAGE_OPTIONS[0] ?? 25,
  );
  const [rangeStart, setRangeStart] = useState<Dayjs | null>(null);
  const [rangeEnd, setRangeEnd] = useState<Dayjs | null>(null);
  const [driverOptions, setDriverOptions] = useState<
    Option[] | null | undefined
  >(null);
  const [searchText, setSearchText] = useState<string>('');
  const [driverSettlementBillUuids, setDriverSettlementBillUuids] = useState<
    string[]
  >([]);

  const [
    selectedSettlementBillLineItemIds,
    setSelectedSettlementBillLineItemIds,
  ] = useState<Set<string>>(new Set());

  const queryVariables = useMemo(() => {
    // If we are fetching line items for a specific driver settlement bill, we only need to apply certain filters
    if (driverSettlementBillUuids.length > 0) {
      return {
        driverSettlementBillUuids,
        searchText: isEmpty(searchText) ? undefined : searchText,
      };
    }

    // Otherwise, we are only fetching unsettled line items
    return {
      onDriverSettlementBill: isEmpty(driverSettlementBillUuids)
        ? false
        : undefined,
      driverUuids: driverOptions?.map((option) => option.value),
      completedDateFilter:
        isNil(rangeStart) || isNil(rangeEnd)
          ? undefined
          : {
              gte: rangeStart?.toISOString(),
              lte: rangeEnd?.toISOString(),
            },
      searchText: isEmpty(searchText) ? undefined : searchText,
      driverSettlementBillUuids,
    };
  }, [
    driverSettlementBillUuids,
    driverOptions,
    rangeStart,
    rangeEnd,
    searchText,
  ]);

  const {
    data,
    loading: loadingSettlementBillLineItems,
    refetch,
  } = useGetSettlementBillLineItemsQuery({
    variables: {
      input: {
        first: rowsPerPage,
        includeCount: true,
        ...queryVariables,
      },
    },
    notifyOnNetworkStatusChange: true,
  });

  const settlementBillLineItems =
    data?.settlementBillLineItems.edges.map((edge) => edge.node) ?? [];
  const pageInfo = data?.settlementBillLineItems.pageInfo;
  const totalCount = data?.settlementBillLineItems.totalCount ?? 0;

  const refresh = async () => {
    setPage(0);
    setSelectedSettlementBillLineItemIds(new Set());

    await refetch({
      input: {
        first: rowsPerPage,
        includeCount: true,
        ...queryVariables,
      },
    });
  };

  const handlePageChange = async ({
    newPage,
    forward,
  }: {
    newPage: number;
    forward: boolean;
  }) => {
    setPage(newPage);

    // When going forward, use only after cursor
    // When going backward, use only before cursor
    const paginationParams = forward
      ? { first: rowsPerPage, after: pageInfo?.endCursor }
      : { last: rowsPerPage, before: pageInfo?.startCursor };

    await refetch({
      input: {
        ...paginationParams,
        includeCount: true,
        ...queryVariables,
      },
    });
  };

  const handleRowsPerPageChange = async (newRowsPerPage: number) => {
    setRowsPerPage(newRowsPerPage);
    setPage(0);
    await refetch({
      input: {
        first: newRowsPerPage,
        includeCount: true,
        ...queryVariables,
      },
    });
  };

  const handleDriverOptionsChange = (
    newDriverOptions: Option[] | null | undefined,
  ) => {
    setDriverOptions(newDriverOptions);
    setPage(0);
    setSelectedSettlementBillLineItemIds(new Set());
  };

  const handleRangeChange = (newRange: [Dayjs | null, Dayjs | null]) => {
    setRangeStart(newRange[0]);
    setRangeEnd(newRange[1]);
    setPage(0);
    setSelectedSettlementBillLineItemIds(new Set());
  };

  const handleSearchTextChange = (newSearchText: string) => {
    setSearchText(newSearchText);
  };

  const handleDriverSettlementBillUuidsChange = (
    newDriverSettlementBillUuids: string[],
  ) => {
    setDriverSettlementBillUuids(newDriverSettlementBillUuids);
  };

  return {
    refresh,
    selectedSettlementBillLineItemIds,
    setSelectedSettlementBillLineItemIds,
    driverOptions,
    rangeStart,
    rangeEnd,
    setRangeStart,
    setRangeEnd,
    loadingSettlementBillLineItems,
    page,
    rowsPerPage,
    settlementBillLineItems,
    handlePageChange,
    handleRowsPerPageChange,
    handleDriverOptionsChange,
    handleRangeChange,
    handleSearchTextChange,
    searchText,
    totalCount,
    handleDriverSettlementBillUuidsChange,
    driverSettlementBillUuids,
  };
};
