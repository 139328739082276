import { sentenceCase } from 'change-case';
import dayjs from 'dayjs';
import { isEmpty, isEqual, isNil } from 'lodash';
import { filterNotNil } from 'shared/array';
import { isNilOrEmptyString } from 'shared/string';
import { exhaustive } from 'shared/switch';
import { RelativeDateOption } from '../../domains/ag-grid/orders/constants';
import { OrderFilterFieldV2 } from '../../domains/orders/components/enums/order-filters';
import {
  DateFilterOptionV2,
  type DateFilterValueInput,
  type DateRangeFilterInput,
} from '../../generated/graphql';
import {
  BOOL_OPERATIONS,
  DATE_OPERATIONS,
  ENUM_OPERATIONS,
  FLOAT_OPERATIONS,
  INTEGER_OPERATIONS,
  SELECT_OPERATIONS,
  STRING_OPERATIONS,
  FilterGroupOperator,
  type FilterConstructionType,
  type GroupFilterConstructionType,
  type FilterTypes,
  type FilterConstructionFilterType,
  type FilterConstructionValueType,
  FILTER_NAME_LABEL_OVERRIDES,
  type FilterConstructionOperatorType,
  type Option,
  EMPTY_SINGLE_FILTER_CONSTRUCTION_TYPE,
  type SingleFilterConstructionType,
} from './types';

export const isGroupFilterConstructionType = (
  filterConstructionType: FilterConstructionType,
): filterConstructionType is GroupFilterConstructionType => {
  return 'and' in filterConstructionType || 'or' in filterConstructionType;
};

export const getFilterGroupOperator = (
  group: GroupFilterConstructionType,
): FilterGroupOperator =>
  group.and ? FilterGroupOperator.AND : FilterGroupOperator.OR;

export const filterToInputType = (
  filter: NonNullable<FilterConstructionFilterType>,
): FilterTypes => {
  const filterName: OrderFilterFieldV2 = OrderFilterFieldV2[filter];
  switch (filterName) {
    case OrderFilterFieldV2.COMPLETED_AT:
    case OrderFilterFieldV2.DATE_ATTEMPTED:
    case OrderFilterFieldV2.DATE_CREATED:
    case OrderFilterFieldV2.DATE_RECEIVED:
    case OrderFilterFieldV2.DEADLINE_DATE:
    case OrderFilterFieldV2.INBOUND_SERVICE_DATE:
    case OrderFilterFieldV2.OUTBOUND_SERVICE_DATE:
    case OrderFilterFieldV2.INBOUND_COMPLETED_DATE:
    case OrderFilterFieldV2.OUTBOUND_COMPLETED_DATE:
    case OrderFilterFieldV2.INVOICE_DATE:
    case OrderFilterFieldV2.ORDER_SERVICE_DATE:
    case OrderFilterFieldV2.INBOUND_APPOINTMENT_DATE:
    case OrderFilterFieldV2.OUTBOUND_APPOINTMENT_DATE:
    case OrderFilterFieldV2.INBOUND_ROUTE_DATE:
    case OrderFilterFieldV2.OUTBOUND_ROUTE_DATE: {
      return 'date';
    }
    case OrderFilterFieldV2.ACTIVE_TERMINAL:
    case OrderFilterFieldV2.OUTBOUND_TERMINAL:
    case OrderFilterFieldV2.INBOUND_TERMINAL:
    case OrderFilterFieldV2.BUSINESS_DIVISION:
    case OrderFilterFieldV2.CUSTOMER_NAME:
    case OrderFilterFieldV2.INBOUND_DRIVER_NAME:
    case OrderFilterFieldV2.OUTBOUND_DRIVER_NAME:
    case OrderFilterFieldV2.HOLD_REASON:
    case OrderFilterFieldV2.TAGS:
    case OrderFilterFieldV2.SERVICE_LEVEL:
    case OrderFilterFieldV2.LINE_HAUL_LANE: {
      return 'select';
    }
    case OrderFilterFieldV2.ASSIGNED_TO_A_ROUTE:
    case OrderFilterFieldV2.IS_HAZMAT:
    case OrderFilterFieldV2.IS_IN_BOND:
    case OrderFilterFieldV2.IS_FINALIZED:
    case OrderFilterFieldV2.IS_PICKED:
    case OrderFilterFieldV2.IS_CANCELLED:
    case OrderFilterFieldV2.IS_REFUSED:
    case OrderFilterFieldV2.IS_REWEIGHED:
    case OrderFilterFieldV2.IS_SPECIAL:
    case OrderFilterFieldV2.ON_HAND:
    case OrderFilterFieldV2.ON_HOLD:
    case OrderFilterFieldV2.ON_INVOICE:
    case OrderFilterFieldV2.PAPERWORK_COMPLETED:
    case OrderFilterFieldV2.REQUIRES_RECOVERY:
    case OrderFilterFieldV2.INBOUND_APPOINTMENT_REQUIRED:
    case OrderFilterFieldV2.OUTBOUND_APPOINTMENT_REQUIRED:
    case OrderFilterFieldV2.INBOUND_APPOINTMENT_CONFIRMED:
    case OrderFilterFieldV2.OUTBOUND_APPOINTMENT_CONFIRMED:
    case OrderFilterFieldV2.INBOUND_COMPLETED:
    case OrderFilterFieldV2.OUTBOUND_COMPLETED:
    case OrderFilterFieldV2.IS_LINE_HAUL:
    case OrderFilterFieldV2.RECEIVED_AT_ORIGIN:
    case OrderFilterFieldV2.CAN_DISPATCH:
    case OrderFilterFieldV2.TRANSFER_PENDING:
    case OrderFilterFieldV2.REQUIRES_ROUTING:
    case OrderFilterFieldV2.HAS_CONTACT_INFORMATION:
    case OrderFilterFieldV2.INBOUND_NOT_ARRIVED:
    case OrderFilterFieldV2.APPOINTMENT_REQUIRED:
    case OrderFilterFieldV2.APPOINTMENT_SCHEDULED: {
      return 'bool';
    }
    case OrderFilterFieldV2.HAWB:
    case OrderFilterFieldV2.MAWB:
    case OrderFilterFieldV2.INBOUND_ADDRESS:
    case OrderFilterFieldV2.OUTBOUND_ADDRESS:
    case OrderFilterFieldV2.ORDER_NAME:
    case OrderFilterFieldV2.SECONDARY_REFERENCE_NUMBER:
    case OrderFilterFieldV2.UN_NUMBER:
    case OrderFilterFieldV2.INBOUND_ZIPCODE:
    case OrderFilterFieldV2.OUTBOUND_ZIPCODE:
    case OrderFilterFieldV2.INBOUND_ROUTE_NAME:
    case OrderFilterFieldV2.OUTBOUND_ROUTE_NAME:
    case OrderFilterFieldV2.DESTINATION_DETAILS:
    case OrderFilterFieldV2.OSD_REASON:
    case OrderFilterFieldV2.ROUTING_LOCATION:
    case OrderFilterFieldV2.EXTERNAL_NOTES:
    case OrderFilterFieldV2.INBOUND_CONTACT_NAME:
    case OrderFilterFieldV2.OUTBOUND_CONTACT_NAME:
    case OrderFilterFieldV2.INBOUND_ROUTING:
    case OrderFilterFieldV2.OUTBOUND_ROUTING:
    case OrderFilterFieldV2.PROOF_OF_DELIVERY_SIGNEE:
    case OrderFilterFieldV2.INBOUND_CITY:
    case OrderFilterFieldV2.OUTBOUND_CITY: {
      return 'text';
    }
    case OrderFilterFieldV2.TOTAL_WEIGHT:
    case OrderFilterFieldV2.DIM_WEIGHT:
    case OrderFilterFieldV2.TOTAL_CHARGES: {
      return 'float';
    }
    case OrderFilterFieldV2.TOTAL_PIECES:
    case OrderFilterFieldV2.TOTAL_SKIDS: {
      return 'integer';
    }
    case OrderFilterFieldV2.ORDER_SOURCE:
    case OrderFilterFieldV2.INBOUND_STOP_TYPE:
    case OrderFilterFieldV2.OUTBOUND_STOP_TYPE:
    case OrderFilterFieldV2.INBOUND_ADDRESS_TYPE:
    case OrderFilterFieldV2.OUTBOUND_ADDRESS_TYPE:
    case OrderFilterFieldV2.ORDER_STATUS: {
      return 'enum';
    }
    default: {
      return exhaustive(filterName);
    }
  }
};

export const mapDateFilterOptionToRelativeDateOption = (
  value: Exclude<DateFilterOptionV2, DateFilterOptionV2.Static>,
  offsetDays: number,
): RelativeDateOption | null => {
  if (isNil(value)) {
    return null;
  }
  switch (value) {
    case DateFilterOptionV2.BeforeToday: {
      return RelativeDateOption.BeforeToday;
    }
    case DateFilterOptionV2.LastXDays: {
      if (offsetDays === 7) {
        return RelativeDateOption.LastSevenDays;
      }
      if (offsetDays === 15) {
        return RelativeDateOption.LastFifteenDays;
      }
      if (offsetDays === 30) {
        return RelativeDateOption.LastThirtyDays;
      }
      if (offsetDays === 60) {
        return RelativeDateOption.LastSixtyDays;
      }
      break;
    }
    case DateFilterOptionV2.Today: {
      return RelativeDateOption.Today;
    }
    case DateFilterOptionV2.Tomorrow: {
      return RelativeDateOption.Tomorrow;
    }
    case DateFilterOptionV2.Yesterday: {
      return RelativeDateOption.Yesterday;
    }
    default: {
      return exhaustive(value);
    }
  }
  return null;
};

export const isEmptyDateFilterValueInput = (
  value: FilterConstructionValueType,
): boolean => {
  if (isDateFilterValueInput(value)) {
    return value.date === null || value.date === undefined || value.date === '';
  }
  return false;
};

export function isDateFilterValueInput(
  value: FilterConstructionValueType,
): value is DateFilterValueInput {
  return (
    typeof value === 'object' &&
    value !== null &&
    'dateFilterOption' in value &&
    typeof value.dateFilterOption === 'string'
  );
}

export function isDateRangeFilterValueInput(
  value: FilterConstructionValueType,
): value is DateRangeFilterInput {
  return (
    typeof value === 'object' &&
    value !== null &&
    'gte' in value &&
    'lte' in value
  );
}

export const filterValueInputToDisplayName = (
  value: FilterConstructionValueType,
  name: FilterConstructionFilterType,
  options?: Option[] | null,
): string => {
  if (isNil(name) || isNil(value)) {
    return '';
  }
  const findOption = (v: FilterConstructionValueType) =>
    options?.find((option) => option.value === v);
  const valueInputType = filterToInputType(name);

  switch (valueInputType) {
    case 'date': {
      // TODO: clean up after backfill
      // There are three possibles categories for dates right now:
      // 1. single date filters: Date | string
      // 2. DateFilterValueInput is static: DateFilterValueInput
      // 3. DateFilterValueInput is relative(today, yesterday, tomorrow, before today, last X days): DateFilterValueInput
      // We need to support all three until after the backfill

      // covering single date filter
      if (typeof value === 'string' || value instanceof Date) {
        return dayjs(value).format('MM/DD/YYYY');
      }
      if (isDateFilterValueInput(value)) {
        // covering static date filter
        const typedFilterDateInput: DateFilterValueInput = value;
        const hasNoDateValue =
          value.date === null || value.date === undefined || value.date === '';
        if (
          typedFilterDateInput.dateFilterOption === DateFilterOptionV2.Static
        ) {
          if (hasNoDateValue) {
            return 'Any';
          }
          return dayjs(typedFilterDateInput.date).format('MM/DD/YYYY');
        }

        // covering relative date filter
        return (
          mapDateFilterOptionToRelativeDateOption(
            typedFilterDateInput.dateFilterOption!,
            typedFilterDateInput.offsetDays,
          ) ?? ''
        );
      }

      if (isDateRangeFilterValueInput(value)) {
        const typedFilterDateRangeInput: DateRangeFilterInput = value;
        return `${dayjs(typedFilterDateRangeInput.gte).format('MM/DD/YYYY')} and ${dayjs(typedFilterDateRangeInput.lte).format('MM/DD/YYYY')}`;
      }

      return '';
    }
    case 'text':
    case 'bool': {
      return value.toString();
    }
    case 'integer':
    case 'float': {
      return value.toString();
    }
    case 'select':
    case 'enum': {
      if (Array.isArray(value)) {
        return value
          .map((v) => findOption(v)?.label ?? sentenceCase(v))
          .join(', ');
      }
      return findOption(value)?.label ?? sentenceCase(value.toString());
    }
    default: {
      if (typeof value === 'object') {
        // Catch-all until we've finished migrating all filters to V2
        return JSON.stringify(value);
      }
      return exhaustive(valueInputType);
    }
  }
};

export const getFilterOperationsByType = (filterType: FilterTypes) => {
  switch (filterType) {
    case 'select': {
      return SELECT_OPERATIONS;
    }
    case 'date': {
      return DATE_OPERATIONS;
    }
    case 'text': {
      return STRING_OPERATIONS;
    }
    case 'bool': {
      return BOOL_OPERATIONS;
    }
    case 'integer': {
      return INTEGER_OPERATIONS;
    }
    case 'float': {
      return FLOAT_OPERATIONS;
    }
    case 'enum': {
      return ENUM_OPERATIONS;
    }
    default: {
      return exhaustive(filterType);
    }
  }
};

/** Returns true if the filter, op, or value (if required) are empty */
export const isSingleFilterConstructionTypeEmpty = (
  filterConstructionType: SingleFilterConstructionType,
) => {
  // This is a special case that we do allow users to "Apply" but
  // that we actually want to filter out (it's still empty)
  if (isEqual(filterConstructionType, EMPTY_SINGLE_FILTER_CONSTRUCTION_TYPE)) {
    return true;
  }
  const { filter, op, value } = filterConstructionType;
  if (isNilOrEmptyString(filter) || isNilOrEmptyString(op)) {
    return true;
  }
  if (op === 'isBlank' || op === 'isNotBlank') {
    // isBlank / isNotBlank don't require value
    return false;
  }
  return (
    isNilOrEmptyString(value) ||
    isEmptyDateFilterValueInput(value) ||
    (Array.isArray(value) && isEmpty(value))
  );
};

export const filterEmptyFilterConstructionTypes = (
  filterConstructionTypes: FilterConstructionType[],
): FilterConstructionType[] => {
  return filterNotNil(
    filterConstructionTypes.map((filterConstructionType) => {
      if (isGroupFilterConstructionType(filterConstructionType)) {
        const operator = getFilterGroupOperator(filterConstructionType);
        const filteredSubFilters = filterEmptyFilterConstructionTypes(
          filterConstructionType[operator] ?? [],
        );
        return isEmpty(filteredSubFilters)
          ? null
          : { [operator]: filteredSubFilters };
      }
      return isSingleFilterConstructionTypeEmpty(filterConstructionType)
        ? null
        : filterConstructionType;
    }),
  );
};

/**
 * Returns true if the filter construction type has partially empty single filters
 * "Partially empty" means that at least one field is empty, but not all fields are empty
 */
export const isFilterConstructionTypePartiallyEmpty = (
  filterConstructionType: FilterConstructionType,
) => {
  if (isGroupFilterConstructionType(filterConstructionType)) {
    return (
      filterConstructionType.and?.some(
        isFilterConstructionTypePartiallyEmpty,
      ) === true ||
      filterConstructionType.or?.some(
        isFilterConstructionTypePartiallyEmpty,
      ) === true
    );
  }
  if (isEqual(filterConstructionType, EMPTY_SINGLE_FILTER_CONSTRUCTION_TYPE)) {
    return false;
  }
  const { filter, op, value } = filterConstructionType;
  // isBlank / isNotBlank don't require value
  const isValueRequired = op !== 'isBlank' && op !== 'isNotBlank';
  const isValueEmpty =
    isNilOrEmptyString(value) ||
    isEmptyDateFilterValueInput(value) ||
    (Array.isArray(value) && isEmpty(value));
  const emptyStatuses = filterNotNil([
    isNilOrEmptyString(filter),
    isNilOrEmptyString(op),
    isValueRequired ? isValueEmpty : null,
  ]);

  // Return true if one field is empty, but not all fields are empty
  return emptyStatuses.some(Boolean) && !emptyStatuses.every(Boolean);
};

export function isTextFieldOperation({
  filterTypes,
  filterConstructionOperatorType,
}: {
  filterTypes: FilterTypes;
  filterConstructionOperatorType: FilterConstructionOperatorType;
}): boolean {
  const isTextFieldFilterType = filterTypes === 'select';
  const isTextFieldFilterOperation =
    filterConstructionOperatorType === 'contains' ||
    filterConstructionOperatorType === 'startsWith' ||
    filterConstructionOperatorType === 'endsWith';
  return isTextFieldFilterType && isTextFieldFilterOperation;
}

export function isMultiSelectOperation({
  filterTypes,
  filterConstructionOperatorType,
}: {
  filterTypes: FilterTypes;
  filterConstructionOperatorType: FilterConstructionOperatorType;
}): boolean {
  const isMultiSelectFilterType =
    filterTypes === 'select' || filterTypes === 'enum';
  const isMultiSelectFilterOperation =
    filterConstructionOperatorType === 'in' ||
    filterConstructionOperatorType === 'nin';
  return isMultiSelectFilterType && isMultiSelectFilterOperation;
}

export const getFilterNameLabel = (
  filterName: NonNullable<FilterConstructionFilterType>,
): string => {
  return (
    FILTER_NAME_LABEL_OVERRIDES[filterName] ?? OrderFilterFieldV2[filterName]
  );
};

export function getFilterDateObject(
  filterValue: Exclude<DateFilterOptionV2, DateFilterOptionV2.Static>,
  offsetDays: number,
): DateFilterValueInput {
  switch (filterValue) {
    case DateFilterOptionV2.Today:
    case DateFilterOptionV2.Yesterday:
    case DateFilterOptionV2.Tomorrow:
    case DateFilterOptionV2.BeforeToday: {
      return {
        dateFilterOption: filterValue,
        offsetDays: 0,
      };
    }
    case DateFilterOptionV2.LastXDays: {
      return {
        dateFilterOption: filterValue,
        offsetDays,
      };
    }
    default: {
      return exhaustive(filterValue);
    }
  }
}

export const isFilterGroupsEmpty = (
  filterGroups: GroupFilterConstructionType[],
): boolean => {
  return filterGroups.every(
    (group) =>
      (isEmpty(group.and) ||
        (group.and ?? []).every(
          (value) =>
            (!isGroupFilterConstructionType(value) &&
              isSingleFilterConstructionTypeEmpty(value)) ||
            (isGroupFilterConstructionType(value) &&
              isEmpty(value.and) &&
              isEmpty(value.or)),
        )) &&
      (isEmpty(group.or) ||
        (group.or ?? []).every(
          (value) =>
            (!isGroupFilterConstructionType(value) &&
              isSingleFilterConstructionTypeEmpty(value)) ||
            (isGroupFilterConstructionType(value) &&
              isEmpty(value.and) &&
              isEmpty(value.or)),
        )),
  );
};
