import { Box } from '@mui/material';
import { isNil } from 'lodash';
import React, { type FunctionComponent, useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';
import { shallow } from 'zustand/shallow';
import useMe from '../../../common/react-hooks/use-me';
import { DayOfWeekAll, OrderSource } from '../../../generated/graphql';
import useGlobalStore from '../../../layouts/dashboard/global-store';
import { useAppDispatch } from '../../../redux/hooks';
import { fetchAllAccessorials } from '../../accessorials/redux/accessorials-thunks-slice';
import { setAllLegs } from '../../legs/redux/leg-values-slice';
import OrderPage from '../../orders/components/order-form/order-page';
import { updateOneStandardOrderValues } from '../../orders/redux/standard/standard-orders-values-slice';
import {
  initExistingStandardOrderValues,
  initNewStandardOrder,
} from '../../orders/redux/standard/standard-orders-values-thunks';
import { setAllStandardShipmentValues } from '../../shipments/redux/standard-shipments-values-slice';
import { setAllStopValues } from '../../stops/redux/stop-values-slice';
import { FormMode } from './accessorials/common';

type RecurringOrderTemplatePageProps = {
  readonly uuid: string | null;
};

const RecurringOrderTemplatePage: FunctionComponent<
  RecurringOrderTemplatePageProps
> = ({ uuid }) => {
  const [searchParams] = useSearchParams();
  const billingPartyContactUuid = searchParams.get('billingPartyContactUuid');
  const formMode = isNil(uuid) ? FormMode.CREATE : FormMode.EDIT;

  const dispatch = useAppDispatch();
  const { companyConfiguration } = useMe();

  const [orderUuid, setOrderUuid] = useGlobalStore(
    (state) => [state.currentOrderUuid, state.setCurrentOrderUuid],
    shallow,
  );

  const initializeOrder = async () => {
    await dispatch(
      initNewStandardOrder({
        billingPartyContactUuid: billingPartyContactUuid ?? undefined,
        configuration: companyConfiguration,
        isDraftOrder: true,
        source: OrderSource.IsTemplate,
      }),
    )
      .unwrap()
      .then((value) => {
        setOrderUuid(value.uuid);
        dispatch(
          updateOneStandardOrderValues({
            id: value.uuid,
            changes: {
              startDate: new Date(),
              daysOfWeek: [DayOfWeekAll.Monday],
              repeatIntervalWeeks: 1,
              shipperBillOfLadingNumber: 'PUT-HAWB-HERE',
            },
          }),
        );
      });
  };

  useEffect(() => {
    if (typeof uuid === 'string') {
      setOrderUuid(uuid);
    }
    return () => {
      if (!isNil(orderUuid)) {
        setOrderUuid(undefined);
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [uuid, setOrderUuid]);

  useEffect(() => {
    if (isNil(companyConfiguration)) {
      return;
    }
    dispatch(fetchAllAccessorials());

    if (formMode === FormMode.CREATE) {
      dispatch(setAllLegs([]));
      dispatch(setAllStopValues([]));
      dispatch(setAllStandardShipmentValues([]));
      initializeOrder();
      return;
    }

    if (typeof uuid === 'string' && uuid !== orderUuid) {
      dispatch(initExistingStandardOrderValues({ orderUuid: uuid }))
        .unwrap()
        .then((existingUuid) => {
          if (!isNil(existingUuid)) {
            setOrderUuid(existingUuid);
          }
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formMode, uuid]);

  if (isNil(companyConfiguration) || isNil(orderUuid)) {
    return <Box>Loading...</Box>;
  }

  return <OrderPage recurringTemplate orderUuid={uuid} />;
};

export default RecurringOrderTemplatePage;
