import { Button } from '@mui/material';
import { IconButton } from '@mui/material';
import { Box, MenuItem, Select, TextField, Typography } from '@mui/material';
import { Controller, useFieldArray, useFormContext } from 'react-hook-form';
import { isNilOrEmptyString } from 'shared/string';
import theme from '../../../../../theme';
import { Stack } from '@mui/material';
import { Fragment, useState } from 'react';
import { type ManifestCreationFormValues } from './create-manifests-button';
import { isNil } from 'lodash';
import {
  type TerminalEntity,
  useTerminalsQuery,
} from '../../../../../generated/graphql';
import RemoveCircleOutlineRoundedIcon from '@mui/icons-material/RemoveCircleOutlineRounded';
import AddIcon from '@mui/icons-material/Add';

const terminalsSortByName = (
  uuidA: string,
  uuidB: string,
  terminals: Array<Pick<TerminalEntity, 'uuid' | 'name'>>,
): number => {
  return (
    terminals
      .find((terminal) => terminal.uuid === uuidA)
      ?.name.localeCompare(
        terminals.find((terminal) => terminal.uuid === uuidB)?.name ?? '',
      ) ?? 0
  );
};

// Representation of a row in the form
type ManifestCreationRow = {
  startTerminalUuid: string;
  endTerminalUuid: string;
  count: number;
};

// Check if the start terminal and end terminal pair exists in the rows array.
// This is used to prevent duplicate rows from being created.
// We check the index so we continue to display the terminal in that given row
const terminalPairExists = ({
  rows,
  startTerminalUuid,
  endTerminalUuid,
  index,
}: {
  rows: ManifestCreationRow[] | null | undefined;
  startTerminalUuid: string;
  endTerminalUuid: string;
  index: number;
}): boolean => {
  if (isNil(rows)) {
    return false;
  }
  return rows.some(
    (row, rowIndex) =>
      row.startTerminalUuid === startTerminalUuid &&
      row.endTerminalUuid === endTerminalUuid &&
      rowIndex !== index,
  );
};

// Check if the start terminal has any remaining end terminals that have not been used yet
// We check the index so we continue to display the terminal in that given row
const isStartTerminalExhausted = ({
  rows,
  terminalLanePairsMap,
  startTerminalUuid,
  index,
}: {
  rows: ManifestCreationRow[] | null | undefined;
  terminalLanePairsMap: Map<string, string[]>;
  startTerminalUuid: string;
  index: number;
}): boolean => {
  return (
    terminalLanePairsMap.get(startTerminalUuid)?.every((endTerminalUuid) =>
      terminalPairExists({
        rows,
        startTerminalUuid,
        endTerminalUuid,
        index,
      }),
    ) ?? false
  );
};

const CreateManifestsLanes = () => {
  // Map of start terminal uuid -> list of end terminal uuids
  const [terminalLanePairsMap, setTerminalLanePairsMap] = useState<
    Map<string, string[]>
  >(new Map());

  const { data: terminals } = useTerminalsQuery({
    fetchPolicy: 'cache-and-network',
    // Populate the terminalLanePairsMap
    onCompleted: (data) => {
      const mapCopy = new Map(terminalLanePairsMap);
      for (const terminal of data.terminals) {
        const { startForTerminalLanes } = terminal;
        if (startForTerminalLanes.length === 0) {
          continue;
        }
        const terminalLanePairs = mapCopy.get(terminal.uuid) ?? [];
        for (const lane of startForTerminalLanes) {
          terminalLanePairs.push(lane.endTerminal.uuid);
        }
        mapCopy.set(terminal.uuid, terminalLanePairs);
      }
      setTerminalLanePairsMap(mapCopy);
    },
  });

  const {
    control,
    getValues,
    formState: { errors: formErrors },
  } = useFormContext<ManifestCreationFormValues>();
  const { fields, append, remove, update } = useFieldArray({
    control,
    name: 'rows',
  });

  const addLane = () => {
    append({
      startTerminalUuid: '',
      endTerminalUuid: '',
      count: 1,
    });
  };

  return (
    <>
      <Box
        display="inline-grid"
        gridTemplateColumns="auto min-content"
        columnGap={4}
        rowGap={1}
      >
        <Typography fontWeight="700" fontSize="14px">
          Lanes
        </Typography>
        <Typography fontWeight="700" fontSize="14px">
          Loads
        </Typography>
        {fields.map((field, index) => (
          <Fragment key={`${field.id}`}>
            <Stack direction="row" gap={1} alignItems="center">
              <Controller
                name={`rows.${index}.startTerminalUuid`}
                control={control}
                render={({ field }) => (
                  <Select
                    displayEmpty
                    value={field.value}
                    size="small"
                    sx={{ width: '4.5em' }}
                    error={Boolean(formErrors.rows?.[index]?.startTerminalUuid)}
                    onChange={(e) => {
                      const field = getValues(`rows.${index}`);
                      update(index, {
                        ...field,
                        startTerminalUuid: e.target.value,
                      });
                    }}
                  >
                    {[...terminalLanePairsMap.keys()]
                      .filter(
                        (key) =>
                          !isStartTerminalExhausted({
                            rows: getValues('rows'),
                            terminalLanePairsMap,
                            startTerminalUuid: key,
                            index,
                          }),
                      )
                      .sort((a, b) =>
                        terminalsSortByName(a, b, terminals?.terminals ?? []),
                      )
                      .map((key) => (
                        <MenuItem key={key} value={key}>
                          <Typography>
                            {
                              terminals?.terminals.find(
                                (terminal) => terminal.uuid === key,
                              )?.name
                            }
                          </Typography>
                        </MenuItem>
                      ))}
                  </Select>
                )}
              />
              <Typography
                color={theme.palette.concreteGrey[40]}
                fontSize="14px"
              >
                to
              </Typography>
              <Controller
                name={`rows.${index}.endTerminalUuid`}
                control={control}
                render={({ field }) => (
                  <Select
                    value={field.value}
                    size="small"
                    sx={{ width: '4.5em' }}
                    error={Boolean(formErrors.rows?.[index]?.endTerminalUuid)}
                    disabled={isNilOrEmptyString(
                      getValues(`rows.${index}.startTerminalUuid`),
                    )}
                    onChange={(e) => {
                      field.onChange(e.target.value);
                    }}
                  >
                    {terminalLanePairsMap
                      .get(getValues(`rows.${index}.startTerminalUuid`))
                      ?.filter(
                        (endTerminalUuid) =>
                          !terminalPairExists({
                            rows: getValues('rows'),
                            startTerminalUuid: getValues(
                              `rows.${index}.startTerminalUuid`,
                            ),
                            endTerminalUuid,
                            index,
                          }),
                      )
                      .sort((a, b) =>
                        terminalsSortByName(a, b, terminals?.terminals ?? []),
                      )
                      .map((endTerminalUuid) => (
                        <MenuItem key={endTerminalUuid} value={endTerminalUuid}>
                          <Typography>
                            {
                              terminals?.terminals.find(
                                (terminal) => terminal.uuid === endTerminalUuid,
                              )?.name
                            }
                          </Typography>
                        </MenuItem>
                      ))}
                  </Select>
                )}
              />
            </Stack>
            <Stack direction="row" gap={1} alignItems="center">
              <Controller
                name={`rows.${index}.count`}
                control={control}
                render={({ field }) => (
                  <TextField
                    size="small"
                    type="number"
                    // Remove leading 0s
                    value={Number(field.value).toString()}
                    sx={{ width: '3.5em' }}
                    inputProps={{
                      style: {
                        textAlign: 'right',
                        paddingRight: '0.5em',
                      },
                    }}
                    error={Boolean(formErrors.rows?.[index]?.count)}
                    onChange={(e) => {
                      field.onChange(Number(e.target.value));
                    }}
                  />
                )}
              />
              <IconButton
                size="small"
                disabled={fields.length === 1}
                onClick={() => {
                  remove(index);
                }}
              >
                <RemoveCircleOutlineRoundedIcon />
              </IconButton>
            </Stack>
          </Fragment>
        ))}
      </Box>
      <Button
        startIcon={<AddIcon />}
        variant="outlined"
        size="small"
        onClick={addLane}
      >
        Add lane
      </Button>
    </>
  );
};

export default CreateManifestsLanes;
