import { Card, CircularProgress, Stack } from '@mui/material';
import { isNil } from 'lodash';
import { useEffect, useState } from 'react';
import {
  FormProvider,
  type SubmitErrorHandler,
  type SubmitHandler,
} from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { shallow } from 'zustand/shallow';
import {
  type MutationErrorOutput,
  OrganizationDocument,
  type OrganizationFragment,
  OrganizationType,
  useArchiveOrganizationMutation,
  useCreateOrganizationMutation,
  useRestoreOrganizationMutation,
  useUpdateOrganizationMutation,
} from '../../../../generated/graphql';
import useGlobalStore from '../../../../layouts/dashboard/global-store';
import {
  OrganizationPageMode,
  OrganizationPageTabs,
  type OrganizationPageProps,
} from '../../enums';
import { type OrganizationFormValues } from '../../form/types';
import useOrganizationForm from '../../form/use-organization-form';
import {
  convertFormValuesToOrganizationCreateInput,
  convertFormValuesToOrganizationUpdateInput,
} from '../../form/utils';
import GeneralTabForm from './general-tab-form';
import ArchiveActionComponent, {
  ArchiveableEntity,
} from '../../../management/components/common/archive-action-component';
import UnsavedChangesModal from '../../components/unsaved-changes-modal';

const GeneralTab = ({
  pageProps,
  organizationData,
  dataLoading,
  refetchOrganization,
  pendingTabChange,
  setPendingTabChange,
  setIsGeneralTabFormDirty,
  completePendingTabChange,
}: {
  readonly pageProps: OrganizationPageProps;
  readonly organizationData: OrganizationFragment | null;
  readonly dataLoading: boolean;
  readonly refetchOrganization: () => void;
  readonly pendingTabChange: OrganizationPageTabs | null;
  readonly setPendingTabChange: (tab: OrganizationPageTabs | null) => void;
  readonly setIsGeneralTabFormDirty: (dirty: boolean) => void;
  readonly completePendingTabChange: () => void;
}) => {
  const navigate = useNavigate();
  const [selectedTypes, setSelectedTypes] = useState<OrganizationType[]>([
    OrganizationType.Customer,
  ]);

  const [unsavedChangesModalOpen, setUnsavedChangesModalOpen] = useState(false);

  const [
    setSuccessMessage,
    setShowSuccessMessage,
    setErrorMessage,
    setShowErrorMessage,
  ] = useGlobalStore(
    (state) => [
      state.setSuccessMessage,
      state.setShowSuccessMessage,
      state.setErrorMessage,
      state.setShowErrorMessage,
    ],
    shallow,
  );

  const {
    form,
    loading: formLoading,
    isAgent,
    isCustomer,
  } = useOrganizationForm({
    mode: pageProps.mode,
    organizationTypes: selectedTypes,
    data: organizationData ?? null,
    dataLoading,
  });

  const {
    formState: { isDirty },
  } = form;

  useEffect(() => {
    if (!isNil(organizationData)) {
      setSelectedTypes(organizationData.types);
    }
  }, [organizationData]);

  // listen for a pending tab change
  useEffect(() => {
    if (
      !isNil(pendingTabChange) &&
      pendingTabChange !== OrganizationPageTabs.GENERAL
    ) {
      setUnsavedChangesModalOpen(true);
    }
  }, [pendingTabChange]);

  useEffect(() => {
    setIsGeneralTabFormDirty(isDirty);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isDirty]);

  const [createOrganization, { loading: createOrganizationLoading }] =
    useCreateOrganizationMutation({
      onCompleted: () => {
        setSuccessMessage('Organization created successfully');
        setShowSuccessMessage(true);
        navigate('/directory');
      },
      onError: () => {
        setErrorMessage('There was an error creating organization');
        setShowErrorMessage(true);
      },
    });
  const [restoreOrganization, { loading: restoreOrganizationLoading }] =
    useRestoreOrganizationMutation({
      onCompleted: async (data) => {
        if (data.restoreOrganization.__typename === 'MutationErrorOutput') {
          const mutationError: MutationErrorOutput = data.restoreOrganization;
          setErrorMessage(mutationError.message);
          setShowErrorMessage(true);
        }
        if (
          data.restoreOrganization.__typename ===
          'RestoreOrganizationSuccessOutput'
        ) {
          setSuccessMessage('Organization restored');
          setShowSuccessMessage(true);
        }
      },
      onError: (error) => {
        setErrorMessage('Failed to restore organization');
        setShowErrorMessage(true);
      },
      refetchQueries: [OrganizationDocument],
    });

  const [archiveOrganization, { loading: archiveOrganizationLoading }] =
    useArchiveOrganizationMutation({
      onCompleted: async (data) => {
        if (
          !isNil(data.archiveOrganization) &&
          data.archiveOrganization.__typename === 'MutationErrorOutput'
        ) {
          const mutationError: MutationErrorOutput = data.archiveOrganization;
          setErrorMessage(mutationError.message);
          setShowErrorMessage(true);
        } else {
          setSuccessMessage('Organization archived');
          setShowSuccessMessage(true);
          navigate('/directory');
        }
      },
      onError: (error) => {
        setErrorMessage('Failed to archive organization');
        setShowErrorMessage(true);
      },
    });

  const [updateOrganization, { loading: updateOrganizationLoading }] =
    useUpdateOrganizationMutation({
      onCompleted: (data) => {
        if (isNil(data.updateOrganization)) {
          return;
        }
        if (
          data.updateOrganization.__typename ===
          'UpdateOrganizationSuccessOutput'
        ) {
          setSuccessMessage('Organization updated successfully');
          setShowSuccessMessage(true);
        } else if (
          data.updateOrganization.__typename === 'MutationErrorOutput'
        ) {
          setErrorMessage(data.updateOrganization.message);
          setShowErrorMessage(true);
        }
      },
      onError: () => {
        setErrorMessage('There was an error updating organization');
        setShowErrorMessage(true);
      },
      refetchQueries: [OrganizationDocument],
    });

  if (dataLoading || archiveOrganizationLoading || restoreOrganizationLoading) {
    return <CircularProgress />;
  }

  const onSubmit: SubmitHandler<OrganizationFormValues> = async (
    data: OrganizationFormValues,
  ) => {
    switch (pageProps.mode) {
      case OrganizationPageMode.CREATE: {
        const createInput = convertFormValuesToOrganizationCreateInput(data);
        await createOrganization({
          variables: {
            input: createInput,
          },
        });
        break;
      }
      case OrganizationPageMode.EDIT: {
        const updateInput = convertFormValuesToOrganizationUpdateInput(data);
        await updateOrganization({
          variables: {
            input: updateInput,
          },
        });
        break;
      }
      case OrganizationPageMode.VIEW: {
        break;
      }
    }
  };

  const onError: SubmitErrorHandler<OrganizationFormValues> = (errors) => {
    // show an error snackbar
    setErrorMessage('Your form has errors, please fix them and try again');
    setShowErrorMessage(true);
  };

  return (
    <FormProvider {...form}>
      <Stack gap={2}>
        <GeneralTabForm
          {...pageProps}
          formLoading={formLoading}
          setSelectedOrganizationTypes={(types) => {
            setSelectedTypes(types);
          }}
          isAgent={isAgent}
          isCustomer={isCustomer}
          submitLoading={createOrganizationLoading || updateOrganizationLoading}
          initialTypes={organizationData?.types ?? []}
          onSubmit={form.handleSubmit(onSubmit, onError)}
        />
        {!isNil(organizationData) &&
          pageProps.mode === OrganizationPageMode.EDIT && (
            <Card sx={{ p: 2, boxShadow: 0, border: '1px solid #E0E0E0' }}>
              <ArchiveActionComponent
                entityType={ArchiveableEntity.ACCOUNT}
                isActive={organizationData?.isActive ?? false}
                handleArchive={async () =>
                  archiveOrganization({
                    variables: {
                      input: {
                        uuid: organizationData?.uuid,
                      },
                    },
                  })
                }
                handleUnarchive={async () =>
                  restoreOrganization({
                    variables: {
                      input: {
                        uuid: organizationData?.uuid,
                      },
                    },
                  })
                }
              />
            </Card>
          )}
      </Stack>
      <UnsavedChangesModal
        open={unsavedChangesModalOpen}
        onCancel={() => {
          setPendingTabChange(null);
          setUnsavedChangesModalOpen(false);
        }}
        onDiscard={() => {
          form.reset();
          completePendingTabChange();
          setUnsavedChangesModalOpen(false);
        }}
        onSave={() => {
          form.handleSubmit(onSubmit, onError);
          completePendingTabChange();
          setUnsavedChangesModalOpen(false);
        }}
      />
    </FormProvider>
  );
};

export default GeneralTab;
