import { Fade, TableCell, TableRow } from '@mui/material';
import { useState } from 'react';
import ButtonLink from '../../../../common/components/buttons/button-link';
import { type CustomerPortalQuoteFragment } from '../../../../generated/graphql';

type CustomerPortalQuoteTableRowProps = {
  readonly quote: CustomerPortalQuoteFragment;
};

const CustomerPortalQuoteTableRow = ({
  quote,
}: CustomerPortalQuoteTableRowProps) => {
  const [isHovering, setIsHovering] = useState(false);

  return (
    <TableRow
      onMouseEnter={() => {
        setIsHovering(true);
      }}
      onMouseLeave={() => {
        setIsHovering(false);
      }}
    >
      <TableCell>{quote.number}</TableCell>
      <TableCell>{quote.company.name}</TableCell>
      <TableCell>{quote.createdAt}</TableCell>
      <TableCell>{quote.status}</TableCell>
      <TableCell>
        <Fade in={isHovering}>
          <ButtonLink href="/customer-portal/quotes" variant="contained">
            Open
          </ButtonLink>
        </Fade>
      </TableCell>
    </TableRow>
  );
};

export default CustomerPortalQuoteTableRow;
