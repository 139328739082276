import {
  Box,
  Button,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { transformAddressToFullAddressString } from 'shared/copy';
import { getPermissionsFlags } from 'shared/roles';
import useTerminals from '../../../../common/react-hooks/use-terminals';
import useUserRoles from '../../../../common/react-hooks/use-user-roles';
import {
  PermissionResource,
  type ShallowTerminalFragment,
} from '../../../../generated/graphql';
import { ActiveArchivedButtonGroup } from '../common/active-or-archived-button-group';
import { ActiveOrArchivedTab } from '../common/enums';
import CreateTerminalModal from './create-terminal-modal';

const TerminalsTable = () => {
  const { userPermissions } = useUserRoles();
  const { canWrite: canWriteCompanyTerminals } = getPermissionsFlags(
    userPermissions,
    PermissionResource.CompanyTerminals,
  );

  const [open, setOpen] = useState(false);
  const { terminals, refetchTerminals } = useTerminals({
    includeInactiveTerminals: true,
  });

  const [isActiveOrArchived, setIsActiveOrArchived] =
    useState<ActiveOrArchivedTab>(ActiveOrArchivedTab.ACTIVE);

  const navigate = useNavigate();

  const terminalsFiltered = terminals.filter((t) =>
    isActiveOrArchived === ActiveOrArchivedTab.ACTIVE
      ? t.isActive
      : !t.isActive,
  );
  return (
    <>
      <ActiveArchivedButtonGroup
        activeTab={isActiveOrArchived}
        setTab={setIsActiveOrArchived}
      />
      <Stack direction="column" alignItems="flex-end" sx={{ display: 'flex' }}>
        <Button
          sx={{ marginBottom: '16px' }}
          variant="contained"
          disabled={!canWriteCompanyTerminals}
          onClick={() => {
            setOpen(true);
          }}
        >
          Add terminal
        </Button>
        <TableContainer>
          <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell>Terminal code</TableCell>
                <TableCell>Terminal</TableCell>
                <TableCell>Address</TableCell>
                <TableCell>Agent</TableCell>
                <TableCell />
              </TableRow>
            </TableHead>
            <TableBody>
              {terminalsFiltered?.map((row: ShallowTerminalFragment) => {
                return (
                  <TableRow
                    key={row.uuid}
                    sx={{
                      '&:last-child td, &:last-child th': { border: 0 },
                    }}
                  >
                    <TableCell>
                      <Box>
                        <Typography variant="body1">{row.code}</Typography>
                      </Box>
                    </TableCell>
                    <TableCell>
                      <Box>
                        <Typography variant="body1">{row.name}</Typography>
                      </Box>
                    </TableCell>
                    <TableCell>
                      <Box>
                        <Typography variant="body1">
                          {transformAddressToFullAddressString(row.address)}
                        </Typography>
                      </Box>
                    </TableCell>
                    <TableCell>
                      <Box>
                        <Typography variant="body1">
                          {row.agent?.name}
                        </Typography>
                      </Box>
                    </TableCell>
                    <TableCell>
                      <Box>
                        <Button
                          fullWidth
                          variant="outlined"
                          disabled={!canWriteCompanyTerminals}
                          onClick={() => {
                            navigate(`/management/terminals/${row.uuid}`);
                          }}
                        >
                          Edit
                        </Button>
                      </Box>
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
      </Stack>
      <CreateTerminalModal
        open={open}
        onCreated={() => {
          setOpen(false);
          void refetchTerminals();
        }}
        onCancel={() => {
          setOpen(false);
        }}
      />
    </>
  );
};

export default TerminalsTable;
