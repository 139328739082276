import {
  Box,
  Checkbox,
  Stack,
  styled,
  Table,
  TableBody,
  TableCell,
  type TableCellProps,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material';

import pluralize from 'pluralize';
import ManifestStatusChip from '../manifest-status-chip';
import {
  getManifestDimWeight,
  getManifestPieces,
  getManifestWeight,
  type LineHaulManifest,
  type NonEmptyArray,
} from './utils';
import type { Dispatch, SetStateAction } from 'react';
import ManifestLaneGroupHeader from './manifest-lane-group-header';
import { type Dayjs } from 'dayjs';

const StyledTableRow = styled(TableRow)({
  '&:last-child td': {
    borderBottom: 'none !important',
  },
});

const Container = styled(Stack)(({ theme }) => ({
  border: `1px solid #E5E7EB`,
  borderRadius: theme.shape.borderRadius,
  backgroundColor: theme.palette.concreteGrey[20],
  overflow: 'hidden',
  minWidth: '650px',
}));

const ManifestTableCell = ({ children, ...props }: TableCellProps) => {
  return (
    <TableCell {...props}>
      <Box
        whiteSpace="nowrap"
        overflow="hidden"
        textOverflow="ellipsis"
        width="100%"
      >
        {children}
      </Box>
    </TableCell>
  );
};

const HeaderTableCell = styled(ManifestTableCell)(() => ({
  color: '#757575',
  fontSize: '12px',
}));

const AggregateTableCell = styled(ManifestTableCell)(({ theme }) => ({
  fontSize: '12px',
  fontWeight: 700,
  color: theme.palette.concreteGrey[50],
}));

// This is the number of columns in the table and should be updated if the number of columns changes
const COLUMNS_COUNT = 6;

type ManifestLaneGroupProps = {
  readonly manifests: NonEmptyArray<LineHaulManifest>;
  readonly selectedManifestIds: Set<string>;
  readonly setSelectedManifestIds: Dispatch<SetStateAction<Set<string>>>;
  readonly setOpenedManifestUuid: Dispatch<SetStateAction<string | null>>;
  readonly planningDate: Dayjs;
};

const ManifestLaneGroup = ({
  manifests,
  selectedManifestIds,
  setSelectedManifestIds,
  setOpenedManifestUuid,
  planningDate,
}: ManifestLaneGroupProps) => {
  const allSelected = manifests.every((m) => selectedManifestIds.has(m.uuid));
  const someSelected = manifests.some((m) => selectedManifestIds.has(m.uuid));

  const handleSelectAll = () => {
    setSelectedManifestIds((prev) => {
      const next = new Set(prev);
      if (allSelected) {
        for (const m of manifests) {
          next.delete(m.uuid);
        }
      } else {
        for (const m of manifests) {
          next.add(m.uuid);
        }
      }
      return next;
    });
  };

  const handleSelectManifest = (uuid: string) => {
    setSelectedManifestIds((prev) => {
      const next = new Set(prev);
      if (next.has(uuid)) {
        next.delete(uuid);
      } else {
        next.add(uuid);
      }
      return next;
    });
  };

  return (
    <Container>
      <ManifestLaneGroupHeader
        manifests={manifests}
        planningDate={planningDate}
      />
      <TableContainer style={{ backgroundColor: 'white' }}>
        <Table size="small" style={{ tableLayout: 'fixed' }}>
          <colgroup>
            <col style={{ width: '5%' }} />
            {Array.from({ length: COLUMNS_COUNT - 1 }).map((_, index) => (
              <col
                // eslint-disable-next-line react/no-array-index-key
                key={index}
                style={{ width: `${95 / COLUMNS_COUNT}%` }}
              />
            ))}
          </colgroup>
          <TableHead>
            <StyledTableRow style={{ whiteSpace: 'nowrap' }}>
              <TableCell sx={{ width: 'fit-content' }}>
                <Checkbox
                  color="primary"
                  checked={allSelected}
                  indeterminate={!allSelected && someSelected}
                  onChange={() => {
                    handleSelectAll();
                  }}
                />
              </TableCell>
              <HeaderTableCell>Manifest</HeaderTableCell>
              <HeaderTableCell align="right">Packages</HeaderTableCell>
              <HeaderTableCell align="right">Orders</HeaderTableCell>
              <HeaderTableCell align="right">Weight (lbs)</HeaderTableCell>
              <HeaderTableCell align="right">DIM wt.</HeaderTableCell>
              <HeaderTableCell>Status</HeaderTableCell>
            </StyledTableRow>
          </TableHead>
          <TableBody>
            {manifests.map((manifest) => (
              <StyledTableRow
                key={manifest.uuid}
                hover
                sx={{ cursor: 'pointer' }}
                onClick={() => {
                  setOpenedManifestUuid(manifest.uuid);
                }}
              >
                <TableCell sx={{ width: 'fit-content' }}>
                  <Checkbox
                    color="primary"
                    checked={selectedManifestIds.has(manifest.uuid)}
                    onClick={(e) => {
                      e.stopPropagation();
                    }}
                    onChange={(e) => {
                      handleSelectManifest(manifest.uuid);
                    }}
                  />
                </TableCell>
                <ManifestTableCell style={{ maxWidth: '100px' }}>
                  {manifest.referenceNumber}
                </ManifestTableCell>
                <ManifestTableCell align="right">
                  {getManifestPieces(manifest)}
                </ManifestTableCell>
                <ManifestTableCell align="right">
                  {manifest.orders.length}
                </ManifestTableCell>
                <ManifestTableCell align="right">
                  {getManifestWeight(manifest).toFixed(2)}
                </ManifestTableCell>
                <ManifestTableCell align="right">
                  {getManifestDimWeight(manifest).toFixed(2)}
                </ManifestTableCell>
                <ManifestTableCell align="right">
                  <ManifestStatusChip status={manifest.status} />
                </ManifestTableCell>
              </StyledTableRow>
            ))}
            <StyledTableRow>
              <AggregateTableCell />
              <AggregateTableCell>
                {manifests.length} {pluralize('load', manifests.length)} total
              </AggregateTableCell>
              <AggregateTableCell align="right">
                {manifests.reduce(
                  (sum, manifest) => sum + getManifestPieces(manifest),
                  0,
                )}
              </AggregateTableCell>
              <AggregateTableCell align="right">
                {manifests.reduce(
                  (sum, manifest) => sum + manifest.orders.length,
                  0,
                )}
              </AggregateTableCell>
              <AggregateTableCell align="right">
                {manifests
                  .reduce(
                    (sum, manifest) => sum + getManifestWeight(manifest),
                    0,
                  )
                  .toFixed(2)}
              </AggregateTableCell>
              <AggregateTableCell align="right">
                {manifests
                  .reduce(
                    (sum, manifest) => sum + getManifestDimWeight(manifest),
                    0,
                  )
                  .toFixed(2)}
              </AggregateTableCell>
              <AggregateTableCell align="right" />
            </StyledTableRow>
          </TableBody>
        </Table>
      </TableContainer>
    </Container>
  );
};

export default ManifestLaneGroup;
