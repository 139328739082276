import {
  Button,
  Menu,
  MenuItem,
  type PopoverOrigin,
  type SxProps,
  type Theme,
} from '@mui/material';
import { isNil } from 'lodash';
import { ChevronDownIcon } from 'primereact/icons/chevrondown';
import { Fragment, useState } from 'react';
import { shallow } from 'zustand/shallow';
import { stopPropagation } from '../../../common/utils/events';
import {
  LineHaulManifestsDocument,
  useAddOrdersToManifestV2Mutation,
  useLineHaulManifestsQuery,
} from '../../../generated/graphql';
import useLineHaulDispatchStore from '../store/line-haul-dispatch-store';
import { useGroupedManifests } from './manifests-section/hooks/use-grouped-manifests';
import { exhaustive } from 'shared/switch';

const popoverAnchorOrigin: PopoverOrigin = {
  vertical: 'bottom',
  horizontal: 'right',
};

const popoverTransformOrigin: PopoverOrigin = {
  vertical: 'top',
  horizontal: 'right',
};

type AddToLineHaulManifestButtonProps = {
  readonly sx?: SxProps<Theme>;
  readonly selectedOrderUuids: string[];
  readonly refreshGrid: (
    // Defaults to true.
    shouldDeselectCheckboxes?: boolean,
    // Defaults to true.
    refreshServerSide?: boolean,
  ) => void;
};

export const AddToLineHaulManifestButton = (
  props: AddToLineHaulManifestButtonProps,
) => {
  const [planningDate, setSnackbarSuccessMessage, setSnackbarErrorMessage] =
    useLineHaulDispatchStore(
      (state) => [
        state.planningDate,
        state.setSnackbarSuccessMessage,
        state.setSnackbarErrorMessage,
      ],
      shallow,
    );
  const { data: lineHaulManifestsData, loading: lineHaulManifestsLoading } =
    useLineHaulManifestsQuery({
      fetchPolicy: 'cache-first',
      variables: { date: planningDate.toDate() },
      onError: (error) => {
        setSnackbarErrorMessage(error.message);
      },
    });

  const [anchorElement, setAnchorElement] = useState<HTMLButtonElement | null>(
    null,
  );
  const [addOrdersToManifest] = useAddOrdersToManifestV2Mutation({
    onError: (error) => {
      setSnackbarErrorMessage(error.message);
    },
    onCompleted: (data) => {
      switch (data.addOrdersToManifestV2.__typename) {
        case 'AddOrdersToManifestV2SuccessOutput': {
          const manifest = data.addOrdersToManifestV2.lineHaulManifest;
          if (!isNil(manifest)) {
            setSnackbarSuccessMessage(
              `Orders added to ${manifest.referenceNumber}`,
            );
          }
          props.refreshGrid();

          break;
        }
        case 'AddOrdersToManifestV2PreviousManifestErrorOutput': {
          setSnackbarErrorMessage(
            `Orders cannot be added to this manifest because they are on a previous manifest with a different end terminal: ${data.addOrdersToManifestV2.orderUuids.join(', ')}`,
          );
          break;
        }
        case 'MutationErrorOutput': {
          setSnackbarErrorMessage(data.addOrdersToManifestV2.message);
          break;
        }
        default: {
          exhaustive(data.addOrdersToManifestV2);
        }
      }
    },
    refetchQueries: [LineHaulManifestsDocument],
  });
  const manifests = lineHaulManifestsData?.lineHaulManifests.lineHaulManifests;
  const groupedManifests = useGroupedManifests(manifests ?? []);

  const handleButtonClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorElement(event.currentTarget);
  };
  const handlePopoverClose = () => {
    setAnchorElement(null);
  };
  const handleAddOrdersToManifest = (manifestUuid: string) => {
    void addOrdersToManifest({
      variables: {
        addOrdersToManifestV2Input: {
          manifestUuid,
          orderUuids: props.selectedOrderUuids,
        },
      },
    });
    handlePopoverClose();
  };

  return (
    <>
      <Button
        sx={props.sx}
        variant="contained"
        endIcon={<ChevronDownIcon />}
        disabled={
          props.selectedOrderUuids.length === 0 ||
          lineHaulManifestsLoading ||
          isNil(manifests) ||
          manifests.length === 0
        }
        id="add-to-line-haul-manifest-button"
        onClick={handleButtonClick}
      >
        Add to manifest
      </Button>
      <Menu
        anchorEl={anchorElement}
        open={Boolean(anchorElement)}
        anchorOrigin={popoverAnchorOrigin}
        transformOrigin={popoverTransformOrigin}
        MenuListProps={{
          'aria-labelledby': 'add-to-line-haul-manifest-button',
        }}
        onClose={handlePopoverClose}
      >
        {groupedManifests.map(([pairKey, manifests]) => (
          <Fragment key={pairKey}>
            <MenuItem
              key={pairKey}
              disabled
              sx={{
                fontSize: '14px',
                fontWeight: 500,
              }}
              onClick={stopPropagation}
            >
              {pairKey}
            </MenuItem>
            {manifests.map((manifest) => (
              <MenuItem
                key={manifest.uuid}
                onClick={() => {
                  handleAddOrdersToManifest(manifest.uuid);
                }}
              >
                {manifest.referenceNumber}
              </MenuItem>
            ))}
          </Fragment>
        ))}
      </Menu>
    </>
  );
};
