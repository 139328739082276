import { Stack, Tab, Tabs } from '@mui/material';
import QuotesTable from '../components/quotes-table/quotes-table';
import { useState } from 'react';
import TabPanel from '../../../common/components/tab-panel/tab-panel';
import {
  type FindQuoteFiltersInput,
  QuoteSource,
  QuoteStatus,
} from '../../../generated/graphql';

enum QuotesPageTabs {
  PendingQuotes = 'Pending Quotes',
  ApprovedQuotes = 'Approved Quotes',
  CustomerPortalLeads = 'Customer Portal Leads',
}

const QuotesPageNew = () => {
  const tabsConfig: Array<{
    label: QuotesPageTabs;
    defaultTableFilters: FindQuoteFiltersInput;
  }> = [
    {
      label: QuotesPageTabs.PendingQuotes,
      defaultTableFilters: {
        statusFilter: {
          in: [QuoteStatus.Pending, QuoteStatus.PendingContactApproval],
        },
      },
    },
    {
      label: QuotesPageTabs.ApprovedQuotes,
      defaultTableFilters: {
        statusFilter: {
          in: [QuoteStatus.Approved],
        },
      },
    },
    {
      label: QuotesPageTabs.CustomerPortalLeads,
      defaultTableFilters: {
        sourceFilter: {
          in: [QuoteSource.CustomerPortal],
        },
      },
    },
  ];

  const [currentTab, setCurrentTab] = useState(QuotesPageTabs.PendingQuotes);

  return (
    <Stack direction="column">
      <Tabs
        value={currentTab}
        sx={{
          paddingLeft: 2,
        }}
        onChange={(_, value: QuotesPageTabs) => {
          setCurrentTab(value);
        }}
      >
        {tabsConfig.map((tab) => (
          <Tab key={tab.label} label={tab.label} value={tab.label} />
        ))}
      </Tabs>
      {tabsConfig.map((tab) => (
        <TabPanel
          key={tab.label}
          panelValue={tab.label}
          selectedValue={currentTab}
          sx={{
            paddingTop: 1,
            paddingLeft: 3,
            paddingRight: 3,
          }}
        >
          <QuotesTable defaultTableFilters={tab.defaultTableFilters} />
        </TabPanel>
      ))}
    </Stack>
  );
};

export default QuotesPageNew;
