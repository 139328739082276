import { isNil } from 'lodash';
import { QuoteSource } from '../../../../../generated/graphql';
import { type CustomerPortalOrderFormValues } from '../../customer-portal-order-form/forms/types';

// TODO: tests

/**
 * Transforms CustomerPortalOrderFormValues into the request format expected by the quotes API
 */
export const getCreateQuoteV1Request = ({
  orderValues,
  clientId,
  customerId,
}: {
  orderValues: CustomerPortalOrderFormValues;
  clientId: string;
  customerId: string;
}) => {
  const { serviceId, packages, inboundInformation, outboundInformation } =
    orderValues;

  return {
    clientId,
    customerId,
    serviceId,
    packages,
    ...(isNil(inboundInformation)
      ? {}
      : {
          inboundInformation: {
            address: {
              name: inboundInformation?.name,
              line1: inboundInformation?.addressLine1,
              line2: inboundInformation?.addressLine2,
              city: inboundInformation?.city,
              state: inboundInformation?.state,
              zipcode: inboundInformation?.zipcode,
              country: inboundInformation?.country,
            },
            serviceDate: inboundInformation?.serviceDate?.format('YYYY-MM-DD'),
            deadlineDate:
              inboundInformation?.deadlineDate?.format('YYYY-MM-DD'),
          },
        }),
    ...(isNil(outboundInformation)
      ? {}
      : {
          outboundInformation: {
            address: {
              name: outboundInformation?.name,
              line1: outboundInformation?.addressLine1,
              line2: outboundInformation?.addressLine2,
              city: outboundInformation?.city,
              state: outboundInformation?.state,
              zipcode: outboundInformation?.zipcode,
              country: outboundInformation?.country,
            },
            serviceDate: outboundInformation?.serviceDate?.format('YYYY-MM-DD'),
            deadlineDate:
              outboundInformation?.deadlineDate?.format('YYYY-MM-DD'),
          },
        }),
    source: QuoteSource.CustomerPortal,
  };
};
